import React,{useState,useEffect} from 'react';
import Header from '../Header';
import Footer from '../Footer';
import Nave from '../Nave';
import axios from 'axios';
import {useParams} from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Edittype () {
    const [name,setName]=useState();
    const [status,setStatus]=useState();
    const [isNameError,setNameError]=useState(false);
    const [isStatusError,setStatusError]=useState(false);
    const warn = { borderWidth: 1, borderColor: '#f44336' }
    const nowarn = { borderWidth: 1, borderColor: '#d9dee3' }
    const {id}=useParams();
    function fetchcate(id){
        axios.get("https://api.mytiffi.voidspy.com/api/tiffin_type/"+id)
        
        .then((resp)=>{
            const data=resp.data;
            console.log(data);
            setName(data.type);
            setStatus(data.status);
        })
    
    }
    
      function handleSubmit()
      {
        if(name=="")
        {
          toast.error("Enter Type");
          setName();
          setNameError(false);
        }else if(status=="")
        {
          toast.error("Enter Status");
          setStatus();
          setStatusError(false);
        }
        else
        {
          const data={
              type:name,
              status:status

          };
         console.log(data);
          axios.patch("https://api.mytiffi.voidspy.com/api/tiffin_type/"+id,data)
          .then((res)=>{
              const data=res.data;
              if(data[0].status=='success')
              {
                window.location.href='/tiffintype';
              }
              else
              {
                console.log(data);
              }
          })
      }
    }
    useEffect(()=>{
        fetchcate(id);
    },[])
    return (
        <React.Fragment>
        <div className="layout-wrapper layout-content-navbar">
              <div className="layout-container">
              <Header/>
              <div className="layout-page">
              <Nave/>
             
              <div className="content-wrapper">
             <div className="container-xxl flex-grow-1 container-p-y">
             <div className="row">
                <div className="col-xl">
                  <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                      <h5 className="mb-0">Type</h5>
                      {/* <small className="text-muted float-end">Default label</small> */}
                    </div>
                    <div className="card-body">
                     
                        <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Type</label>
                          <input type="text" className="form-control" id="basic-default-fullname" placeholder="Type" value={name} 
                          onChange={(name) => {setName(name.target.value)
                          setNameError(false);
                          }}
                            style={isNameError ? warn : nowarn}
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Status</label>
                          
                           <select className="form-control" id="basic-default-fullname" placeholder="Category Name" 
                           onChange={(status) => {setStatus(status.target.value)
                          setStatusError(false);
                          }}
                          style={isStatusError ? warn :nowarn}>
                          <option value={status}>{status}</option>
                        <option value="active">active</option>
                        <option value="inactive">inactive</option>
                          </select>
                        </div>
                        <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Send</button>
                      
                    </div>
                  </div>
                </div>
               
              </div>
            </div>
            

            <div className="content-backdrop fade"></div>
          </div>
             <Footer/>
             </div>
             </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}

export default Edittype;
