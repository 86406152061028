import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import Nave from '../Nave';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
function Addmenucard() {

  const [menuname, setMenuname] = useState('');
  const [catename, setCatename] = useState('');
  const [typename, setType] = useState('');
  const [price, setPrice] = useState('');
  const [category, setCategory] = useState([]);
  // const [meals, setMeals] = useState([]);
  const [tiffintype, setTiffintype] = useState([]);
  const [selectedMeals, setSelectedMeals] = useState([]);
  const [content, setContent] = useState('');
  const [mtype, setMType] = useState('');
  const warn = { borderWidth: 1, borderColor: '#f44336' }
  const nowarn = { borderWidth: 1, borderColor: '#d9dee3' }
  const [isDateError, setDateError] = useState(false);
  const [isNameError, setNameError] = useState(false);
  const [isCatenameError, setCatenameError] = useState(false);
  const [isTypenameError, setTypenameError] = useState(false);
  const [isMTypeError, setMTypeError] = useState(false);
  const [isPriceError, setPriceError] = useState(false);
  const [isSelecteMealError, setSelectMealError] = useState(false);
  
  const [inputFields, setInputFields] = useState([
    { name: '' }
  ])
  const [inputFieldsTuesday, setInputFieldsTuesday] = useState([
    { name: '' }
  ])


  function handleSubmit() {
    if (!catename) {
      setCatenameError(true);
    } 
    else if (!mtype) {
      setMTypeError(true)
    }else if (!menuname) {
      setNameError(true);
    }
    else {
      const menudata = {
        name: menuname,
        cate: catename,
      
       
        type: mtype,
      }
      console.log(menudata);
      axios.post("https://api.mytiffi.voidspy.com/api/sabji", menudata)
        .then((res) => {
          const data = res.data;
          //console.log(data);
          if (data[0].status == 'success') {
            window.location.href = '/sabji_menu';
          }
          else {
            console.log(data);
          }
        })
    }

  }
  // function fetchMeal() {
  //   // axios.get('https://api.mytiffi.voidspy.com/api/mealget')
  //   axios.get('https://api.mytiffi.voidspy.com/api/mealget')
  //     .then((resp) => {
  //       const data = resp.data;
  //       setMeals(data);

  //     });

  // }
  // function handlePlus(meal) {
  //   var t = []
  //   selectedMeals.map((m) => {
  //     if (meal.id == m.meal.id) {
  //       m.qty++

  //     }
  //     t.push(m);
  //   })
  //   setSelectedMeals(t);
  //   console.log(selectedMeals);
  // }
  // function handleMinus(meal) {
  //   var t = []
  //   selectedMeals.map((m) => {
  //     if (meal.id == m.meal.id) {
  //       m.qty--

  //     }
  //     t.push(m);
  //   })
  //   setSelectedMeals(t);
  //   console.log(selectedMeals);
  // }
  // function handleChange(e, meal) {
  //   setSelectMealError(false)
  //   if (e.target.checked) {
  //     var tm = selectedMeals
  //     tm.push({ "meal": meal, "qty": 1, })
  //     setSelectedMeals(tm);
  //   }
  //   else {
  //     var t = []
  //     selectedMeals.map((m) => {
  //       if (meal.name != m.meal.name) {
  //         t.push(m);
  //       }
  //     })
  //     setSelectedMeals(t);
  //     console.log(t);
  //   }
  //   // console.log(selectedMeals);
  // }
  
  function fetchCategory() {
    // axios.get("https://api.mytiffi.voidspy.com/api/category")
    axios.get("https://api.mytiffi.voidspy.com/api/category")
      .then((res) => {
        const data = res.data;
        setCategory(data);
      })
  }
  function fetchTiffin() {
    axios.get('https://api.mytiffi.voidspy.com/api/meal')
      .then((resp) => {
        const data = resp.data;
        setTiffintype(data);

      });

  }


  useEffect(() => {
    fetchCategory();
    //fetchMeal();
    fetchTiffin();
  }, []);
  return (
    <React.Fragment>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header />
          <div className="layout-page">
            <Nave />

            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row">
                  <div className="col-xl">
                    <div className="card mb-4">
                      <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Sabji Menu</h5>
                        {/* <small className="text-muted float-end">Default label</small> */}
                      </div>
                      <div className="card-body">


                        <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Category Name {isCatenameError && <span style={{ color: 'red' }}>Please select Category</span>}</label>
                          <select className="form-control" id="basic-default-fullname" placeholder="Category Name" onChange={(catename) => {
                            setCatename(catename.target.value)
                            setCatenameError(false);
                          }} style={isCatenameError ? warn : nowarn}>
                            <option value="">Select Category</option>
                            {
                              category.map((obj) => {
                                return (
                                  <option value={obj.id}>{obj.name}</option>
                                )
                              })

                            }
                          </select>
                        </div>
                        {/* <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Meal Type {isTypenameError && <span style={{ color: 'red' }}>Please select Meal Type</span>}</label>
                          <select className="form-control" id="basic-default-fullname" placeholder="Category Name" onChange={(typename) => {
                            setType(typename.target.value)
                            setTypenameError(false);
                          }} style={isTypenameError ? warn : nowarn}>
                            <option value="">Select Type</option>
                            {
                              tiffintype.map((obj) => {
                                return (
                                  <option value={obj.name}>{obj.name}</option>
                                )
                              })

                            }
                          </select>
                        </div> */}
                        <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Select Sabji type {isMTypeError && <span style={{ color: 'red' }}>Please select Sabji Type</span>}</label>
                          <select className="form-control" id="basic-default-fullname" placeholder="Category Name" onChange={(mtype) => {
                            setMType(mtype.target.value)
                            setMTypeError(false);
                          }} style={isMTypeError ? warn : nowarn}>
                            <option value="">Select Sabji type </option>
                            <option value="dry">Dry</option>
                            <option value="curry">Curry</option>
                          </select>
                        </div>

                        <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Menu Name {isNameError && <span style={{ color: 'red' }}>Please Enter name</span>}</label>
                          <input type="text" className="form-control" id="basic-default-fullname" name='name' value={menuname}
                            onChange={(menuname) => {
                              setMenuname(menuname.target.value)
                              setNameError(false);
                            }} style={isNameError ? warn : nowarn} />
                        </div>

                        {/* <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Date {isPriceError && <span style={{ color: 'red' }}>Please Select Date</span>}</label>
                          <input type="date" className="form-control" id="basic-default-fullname" name='name' value={price}
                            onChange={(price) => {
                              setPrice(price.target.value)
                              setPriceError(false);
                            }} style={isPriceError ? warn : nowarn} />
                        </div> */}
                       
                        {/* <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Content</label>
                          
                          <SunEditor setOptions={{ height: 700, }} style={{ height: 300, }}
                            onChange={handleTitleChange} />
                        </div> */}
                        <p id="GFG_DOWN"></p>
                        <button type="button" className="btn btn-primary" onClick={() => handleSubmit()}>Store</button>

                      </div>
                    </div>
                  </div>

                </div>
              </div>


              <div className="content-backdrop fade"></div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

export default Addmenucard;
