import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Nave from '../Nave';
import Footer from '../Footer';
import axios from 'axios';
function Tiffinmenucard () {
  const [category,setCategory]=useState([]);
    function fetchCategory(){
      axios.get("https://api.mytiffi.voidspy.com/api/menu_card")
      .then((res)=>{
        const data=res.data;
        setCategory(data);
      })
    }
    function editOption(id)
    {
        window.location.href='edittiffin/'+id;
    }
    function deleteOption(id)
    {
      axios.delete("https://api.mytiffi.voidspy.com/api/menu_card/"+id)
      .then((res)=>{
        const data=res.data;
        if(data[0].status=='success')
        {
            window.location.href='/view_menu_card';
        }
        else
        {
           console.log(data);
        }
      })
    }
    useEffect(()=>{
      fetchCategory()
    },[])
    return (
        <React.Fragment>
        <div className="layout-wrapper layout-content-navbar">
              <div className="layout-container">
              <Header/>
              <div className="layout-page">
              <Nave/>
             
              <div className="content-wrapper">
             <div className="container-xxl flex-grow-1 container-p-y">
              
            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light"></span> Menu card Details</h4>
        <a href="/tiffin_menu_card"><button className="btn btn-success">Add Menu Card</button></a>
        <br/><br/>
<div className="card">
  
  <div className="table-responsive text-nowrap">
    <table className="table">
      <thead>
        <tr>
          <th>Id</th>
          <th>Menu Name</th>
          <th>Price</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody className="table-border-bottom-0">
        
        {
          category.map((obj)=>{
            return(
              <tr>
              <td>{obj.id}</td>
              <td>{obj.name}</td>
              <td>{obj.price}</td>
              <td>
                <div className="dropdown">
                  <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                    <i className="bx bx-dots-vertical-rounded"></i>
                  </button>
                  <div className="dropdown-menu">
                    <a className="dropdown-item" onClick={()=>editOption(obj.id)}><i className="bx bx-edit-alt me-1"></i> Edit</a>
                    <a className="dropdown-item" onClick={()=>deleteOption(obj.id)}><i className="bx bx-trash me-1"></i> Delete</a>
                  </div>
                </div>
              </td>
              </tr>
            )
          })
        }
         
      
      </tbody>
    </table>
  </div>
</div>

                 </div>
                 </div>
                 <Footer/>
                 </div>   
                 </div>
                 </div>
                
               </React.Fragment>
    );
}

export default Tiffinmenucard;
