import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import Nave from '../Nave';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
function Addmenucard() {

  const [menuname, setMenuname] = useState('');
  const [catename, setCatename] = useState('');
  const [typename, setType] = useState('');
  const [price, setPrice] = useState('');
  const [date, setDate] = useState('');
  const [category, setCategory] = useState([]);
  const [meals, setMeals] = useState([]);
  const [menucard, setMenuCard] = useState([]);
  const [menu, setMenu] = useState('');
  const [tiffintype, setTiffintype] = useState([]);
  const [selectedMeals, setSelectedMeals] = useState([]);
  const [content, setContent] = useState('');
  const [mtype, setMType] = useState('');
  const warn = { borderWidth: 1, borderColor: '#f44336' }
  const nowarn = { borderWidth: 1, borderColor: '#d9dee3' }

  const [isNameError, setNameError] = useState(false);
  const [isCatenameError, setCatenameError] = useState(false);
  const [isTypenameError, setTypenameError] = useState(false);
  const [isMTypeError, setMTypeError] = useState(false);
  const [isPriceError, setPriceError] = useState(false);
  const [isDateError, setDateError] = useState(false);
  const [isMenuError, setMenuError] = useState(false);
  const [isSelecteMealError, setSelectMealError] = useState(false);
  const [userinfo, setUserInfo] = useState({
    languages: [],
    response: [],
  });
  const [inputFields, setInputFields] = useState([
    { name: '' }
  ])
  const [inputFieldsTuesday, setInputFieldsTuesday] = useState([
    { name: '' }
  ])


  function handleSubmit() {

    if (!catename) {
      setCatenameError(true);
    } else if (!typename) {
      setTypenameError(true);
    }
    else if (!mtype) {
      setMTypeError(true)
    } else if (selectedMeals.length<=0) {
      setMenuError(true)
    } else if (!date) {
      setDateError(true)
    }
    else if (!price) {
      setPriceError(true)
    }
    // else if (selectedMeals.length <= 0) {
    //   setSelectMealError(true);
    // }
    else {
      const menudata = {
        category: catename,
        ttype: typename,
        mtype: mtype,
        mname: JSON.stringify(selectedMeals),

        // desc: JSON.stringify(selectedMeals),
        price: price,
        date: date,
        selectionsabji: JSON.stringify(userinfo.response),

      }
      console.log(menudata);
      axios.post("https://api.mytiffi.voidspy.com/api/my_tiffin", menudata)
        .then((res) => {
          const data = res.data;
          console.log(data);
          if (data[0].status == 'success') {
            window.location.href = '/menu_card';
          }
          else {
            console.log(data);
          }
        })
    }

  }
  function fetchMeal() {
    // axios.get('https://api.mytiffi.voidspy.com/api/mealget')
    axios.get('https://api.mytiffi.voidspy.com/api/meal')
      .then((resp) => {
        const data = resp.data;
        setMeals(data);

      });

  }
  function fetchMenucard() {
    // axios.get('https://api.mytiffi.voidspy.com/api/mealget')
    axios.get('https://api.mytiffi.voidspy.com/api/menu_card')
      .then((resp) => {
        const data = resp.data;
        setMenuCard(data);

      });

  }
  function handlePlus(meal) {
    var t = []
    selectedMeals.map((m) => {
      if (meal.id == m.meal.id) {
        m.qty++

      }
      t.push(m);
    })
    setSelectedMeals(t);
    console.log(selectedMeals);
  }
  function handleMinus(meal) {
    var t = []
    selectedMeals.map((m) => {
      if (meal.id == m.meal.id) {
        m.qty--

      }
      t.push(m);
    })
    setSelectedMeals(t);
    console.log(selectedMeals);
  }
  function handleChange(e, meal) {
    setSelectMealError(false)
    if (e.target.checked) {
      var tm = selectedMeals
      tm.push({ "meal": meal, "qty": 1, })
      setSelectedMeals(tm);
    }
    else {
      var t = []
      selectedMeals.map((m) => {
        if (meal.name != m.meal.name) {
          t.push(m);
        }
      })
      setSelectedMeals(t);
      console.log(t);
    }
    // console.log(selectedMeals);
  }
  const handleChangeSabji = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { languages } = userinfo;

    console.log(`${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked) {
      setUserInfo({
        languages: [...languages, value],
        response: [...languages, value],
      });
    }

    // Case 2  : The user unchecks the box
    else {
      setUserInfo({
        languages: languages.filter((e) => e !== value),
        response: languages.filter((e) => e !== value),
      });
    }
  };

  function fetchCategory() {
    // axios.get("https://api.mytiffi.voidspy.com/api/category")
    axios.get("https://api.mytiffi.voidspy.com/api/category")
      .then((res) => {
        const data = res.data;
        setCategory(data);
      })
  }
  function fetchTiffin() {
    axios.get('https://api.mytiffi.voidspy.com/api/tiffin_type')
      .then((resp) => {
        const data = resp.data;
        setTiffintype(data);

      });

  }
  function fetchMeal() {
    axios.get('https://api.mytiffi.voidspy.com/api/meal')
      .then((resp) => {
        const data = resp.data;
        setMeals(data);

      });

  }


  useEffect(() => {
    fetchCategory();
    fetchMeal();
    fetchTiffin();
    fetchMenucard();
  }, []);
  return (
    <React.Fragment>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header />
          <div className="layout-page">
            <Nave />

            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row">
                  <div className="col-xl">
                    <div className="card mb-4">
                      <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Tiffin</h5>
                        {/* <small className="text-muted float-end">Default label</small> */}
                      </div>
                      <div className="card-body">

                        {/* <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Menu Name {isNameError && <span style={{ color: 'red' }}>Please Enter name</span>}</label>
                          <input type="text" className="form-control" id="basic-default-fullname" name='name' value={menuname}
                            onChange={(menuname) => {
                              setMenuname(menuname.target.value)
                              setNameError(false);
                            }} style={isNameError ? warn : nowarn} />
                        </div> */}


                        <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Category Name {isCatenameError && <span style={{ color: 'red' }}>Please select Meal Category</span>}</label>
                          <select className="form-control" id="basic-default-fullname" placeholder="Category Name" onChange={(catename) => {
                            setCatename(catename.target.value)
                            setCatenameError(false);
                          }} style={isCatenameError ? warn : nowarn}>
                            <option value="">Select Category</option>
                            {
                              category.map((obj) => {
                                return (
                                  <option value={obj.id}>{obj.name}</option>
                                )
                              })

                            }
                          </select>
                        </div>
                        <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Tiffin Type {isTypenameError && <span style={{ color: 'red' }}>Please select Tiffin Type</span>}</label>
                          <select className="form-control" id="basic-default-fullname" placeholder="Category Name" onChange={(typename) => {
                            setType(typename.target.value)
                            setTypenameError(false);
                          }} style={isTypenameError ? warn : nowarn}>
                            <option value="">Select Type</option>
                            {
                              tiffintype.map((obj) => {
                                return (
                                  <option value={obj.id}>{obj.type}</option>
                                )
                              })

                            }
                          </select>
                        </div>
                        <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Select Meal type {isMTypeError && <span style={{ color: 'red' }}>Please select Meal Type</span>}</label>
                          <select className="form-control" id="basic-default-fullname" placeholder="Category Name" onChange={(mtype) => {
                            setMType(mtype.target.value)
                            setMTypeError(false);
                          }} style={isMTypeError ? warn : nowarn}>
                            <option value="">Select Type</option>
                            {
                              meals.map((obj) => {
                                return (
                                  <option value={obj.id}>{obj.name}</option>
                                )
                              })

                            }
                          </select>
                        </div>
                        <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Select Menu Card {isMenuError && <span style={{ color: 'red' }}>Please select Menu Card</span>}</label>
                          {/* <select className="form-control" id="basic-default-fullname" placeholder="Category Name" onChange={(menu) => {
                            setMenu(menu.target.value)
                            setMenuError(false);
                          }} style={isMenuError ? warn : nowarn}>
                            <option value="">Select Menu Card</option>
                            {
                              menucard.map((obj) => {
                                return (
                                  <option value={obj.id}>{obj.name}</option>
                                )
                              })

                            }
                          </select> */}
                          <div className='row'>
                            {
                              menucard.map((meal) => {
                                var found;
                                selectedMeals.map((mls) => {
                                  if (mls.meal.id == meal.id) {
                                    found = mls;
                                  }
                                })
                                if (found) {
                                  return (
                                    <div className='col-sm-4'>
                                      <input type="checkbox" class="form-check-input" key={meal.id} onChange={(e) => handleChange(e, meal)} /> {meal.name}
                                      <input type="button" value="-" class="button-minus" data-field="quantity" onClick={() => { handleMinus(meal) }}
                                        id="checkout_inc1" style={{ borderRadius: '50%', borderColor: 'white', marginLeft: 10, backgroundColor: 'red', color: 'white' }} />
                                      <input type="number" step="1" max="" id="checkout_inc2" value={found.qty} name="quantity" class="quantity-field" style={{ width: 40, marginLeft: 10, }} />
                                      <input type="button" value="+" class="button-plus" data-field="quantity" onClick={() => { handlePlus(meal) }}
                                        id="checkout_inc1" style={{ borderRadius: '50%', borderColor: 'white', marginLeft: 10, backgroundColor: 'red', color: 'white' }} />
                                    </div>
                                  )
                                }
                                else {
                                  return (
                                    <div className='col-sm-4'>
                                      <input type="checkbox" class="form-check-input" key={meal.id} onChange={(e) => handleChange(e, meal)} /> {meal.name}
                                      <input type="button" value="-" class="button-minus" data-field="quantity" onClick={() => { handleMinus(meal) }}
                                        id="checkout_inc1" style={{ borderRadius: '50%', borderColor: 'white', marginLeft: 10, backgroundColor: 'red', color: 'white' }} />
                                      <input type="number" step="1" max="" id="checkout_inc2" value={1} name="quantity" class="quantity-field" style={{ width: 40, marginLeft: 10, }} />
                                      <input type="button" value="+" class="button-plus" data-field="quantity" onClick={() => { handlePlus(meal) }}
                                        id="checkout_inc1" style={{ borderRadius: '50%', borderColor: 'white', marginLeft: 10, backgroundColor: 'red', color: 'white' }} />
                                    </div>
                                  )
                                }
                              })
                            }
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Sabji Selection</label><br />
                          {/* <select className="form-control" id="basic-default-fullname" placeholder="Category Name" onChange={(mtype) => {
                            setMenu(mtype.target.value)
                            setMenuError(false);
                          }} style={isMTypeError ? warn : nowarn}>
                            <option value="">Select Menu Card</option>
                            {
                              menucard.map((obj) => {
                                return (
                                  <option value={obj.id}>{obj.name}</option>
                                )
                              })

                            }
                          </select> */}
                          <input type="checkbox" style={{ marginLeft: "20px" }} onChange={handleChangeSabji} value="Dry" />Dry
                          <input type="checkbox" style={{ marginLeft: "20px" }} onChange={handleChangeSabji} value="Curry" />Curry
                        </div>
                        <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Date {isDateError && <span style={{ color: 'red' }}>Please Choose Date</span>}</label>
                          <input type="date" className="form-control" id="basic-default-fullname" name='date' value={date}
                            onChange={(date) => {
                              setDate(date.target.value)
                              setDateError(false);
                            }} style={isDateError ? warn : nowarn} />
                        </div>
                        <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Price {isPriceError && <span style={{ color: 'red' }}>Please enter price</span>}</label>
                          <input type="text" className="form-control" id="basic-default-fullname" name='name' value={price}
                            onChange={(price) => {
                              setPrice(price.target.value)
                              setPriceError(false);
                            }} style={isPriceError ? warn : nowarn} />
                        </div>



                        {/* <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Content</label>
                          
                          <SunEditor setOptions={{ height: 700, }} style={{ height: 300, }}
                            onChange={handleTitleChange} />
                        </div> */}
                        <p id="GFG_DOWN"></p>
                        <button type="button" className="btn btn-primary" onClick={() => handleSubmit()}>Store</button>

                      </div>
                    </div>
                  </div>

                </div>
              </div>


              <div className="content-backdrop fade"></div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

export default Addmenucard;
