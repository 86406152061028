import React,{useState} from 'react';
import Header from '../Header';
import Footer from '../Footer';
import Nave from '../Nave';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
function Addmeal () {
    const [name,setName]=useState('');
    const [desc,setDesc]=useState('');
    const [content,setContent]=useState('');
    const [price,setPrice]=useState('');
    const [isNameError,setNameError]=useState(false);
    const [isDescError,setDescError]=useState(false);
    const [isContentError,setContentError]=useState(false);
    const [isPriceError,setPriceError]=useState(false);
    const warn = { borderWidth: 1, borderColor: '#f44336' }
    const nowarn = { borderWidth: 1, borderColor: '#d9dee3' }
    function handleTitleChange(datacontent)
    {
      setContent(datacontent)
    }
    function handleSubmit()
    {
      if(name=="")
      {
        toast.error("Enter Name");
        setName();
        setNameError(true);
      }
      else
      {
            const data={
                name:name,
                description:desc,
                content:content,
                price:price
            };
            // axios.post("https://api.mytiffi.voidspy.com/api/meal",data)
            axios.post("https://api.mytiffi.voidspy.com/api/meal",data)
            .then((res)=>{
                const data=res.data;
                if(data[0].status=='success')
                {
                    window.location.href='/meals';
                }
                else
                {
                    console.log(data);
                }
            })
        }
    }
    return (
        <React.Fragment>
        <div className="layout-wrapper layout-content-navbar">
              <div className="layout-container">
              <Header/>
              <div className="layout-page">
              <Nave/>
             
              <div className="content-wrapper">
             <div className="container-xxl flex-grow-1 container-p-y">
             <div className="row">
                <div className="col-xl">
                  <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                      <h5 className="mb-0">Meal</h5>
                      {/* <small className="text-muted float-end">Default label</small> */}
                    </div>
                    <div className="card-body">
                     
                        <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Name</label>
                          <input type="text" className="form-control" id="basic-default-fullname" placeholder="Name" value={name} 
                          onChange={(name) => {setName(name.target.value)
                          setNameError(false)
                          }}
                            style={isNameError ? warn : nowarn}/>
                        </div>
                       
                        
                        <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Send</button>
                      
                    </div>
                  </div>
                </div>
               
              </div>
            </div>
            

            <div className="content-backdrop fade"></div>
          </div>
             <Footer/>
             </div>
             </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}

export default Addmeal;
