import React from 'react';

function Footer () {
    return (
        <div>
        <footer className="content-footer footer bg-footer-theme">
              <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                <div className="mb-2 mb-md-0">
                  ©
                  <script>
                    document.write(new Date().getFullYear());
                  </script>
                  , product by &nbsp;
                  <a href="https://voidspy.com" target="_blank" className="footer-link fw-bolder"> voidspy</a>
                </div>
                {/* <div>
                  <a href="https://voidspy.com/license/" className="footer-link me-4" target="_blank">License</a>
                  <a href="https://voidspy.com/" target="_blank" className="footer-link me-4">More Themes</a>

                  <a href="https://voidspy.com/demo/sneat-bootstrap-html-admin-template/documentation/"
                    target="_blank" className="footer-link me-4">Documentation</a>

                  <a href="https://github.com/voidspy/sneat-html-admin-template-free/issues"
                    target="_blank"
                    className="footer-link me-4">Support</a>
                </div> */}
              </div>
            </footer>
             

            <div className="content-backdrop fade">
                
            </div>
          
          
       
      <div className="layout-overlay layout-menu-toggle"></div>
</div>
    );
}

export default Footer;
