import { useState, useEffect } from 'react';

function MyComponent() {
  const [dates, setDates] = useState([]);

  function Add()
  {
    const currentDate = new Date();
    const oneWeekLater = new Date(currentDate);
    oneWeekLater.setDate(currentDate.getDate() + 7);

    const formattedDates = [];
    let currentDateIterator = new Date(currentDate);
    while (currentDateIterator <= oneWeekLater) {
      const year = currentDateIterator.getFullYear();
      const month = String(currentDateIterator.getMonth() + 1).padStart(2, '0');
      const day = String(currentDateIterator.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      formattedDates.push(formattedDate);
      currentDateIterator.setDate(currentDateIterator.getDate() + 1);
    }
    console.log(formattedDates)
    setDates(formattedDates);
  }
  useEffect(() => {
    Add()
  }, []);
 

  return (
    <div>
      <h2>Dates for the next week:</h2>
      <ul>
        {dates.map((date) => (
          <li key={date}>{date}</li>
        ))}
      </ul>
    </div>
  );
}
export default MyComponent;