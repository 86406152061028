import React, { useState, useEffect } from 'react';
import Header from './Header';
import Nave from './Nave';
import Footer from './Footer';
import axios from 'axios';
function Category () {
  const [category,setCategory]=useState([]);
    function fetchCategory(){
      axios.get("http://localhost:8000/api/category")
      .then((res)=>{
        const data=res.data;
        setCategory(data);
      })
    }
    function editOption(id)
    {
        window.location.href='editcategory/'+id;
    }
    function deleteOption(id)
    {
      axios.delete("http://localhost:8000/api/category/"+id)
      .then((res)=>{
        const data=res.data;
        if(data[0].status=='status')
        {
            window.location.href='/category';
        }
        else
        {
           console.log(data);
        }
      })
    }
    useEffect(()=>{
      fetchCategory()
    },[])
    return (
        <React.Fragment>
        <div className="layout-wrapper layout-content-navbar">
              <div className="layout-container">
              <Header/>
              <div className="layout-page">
              <Nave/>
             
              <div className="content-wrapper">
          
                 </div>
                 <Footer/>
                 </div>   
                 </div>
                 </div>
                
               </React.Fragment>
    );
}

export default Category;
