import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Nave from '../Nave';
import Footer from '../Footer';
import axios from 'axios';
function Plan () {
    const [plan,setPlan]=useState([]);
    function fetchplan(){
        axios.get("http://api.mytiffi.voidspy.com/api/plan")
        .then((res)=>{
            const data=res.data;
            setPlan(data);
        })
    }
    function editOption(id)
    {
         window.location.href='editplan/'+id;   
    }
    function deleteOption(id)
    {
        axios.delete("http://api.mytiffi.voidspy.com/api/plan/"+id)
        .then((res)=>{
            const data=res.data;
            if(data[0].status=='success')
            {
                window.location.href='/plans';
            }
            else
            {
                console.log(data);
            }
        })
    }
    useEffect(()=>{
        fetchplan();
    },[])
    return (
        
        <React.Fragment>
        <div className="layout-wrapper layout-content-navbar">
              <div className="layout-container">
              <Header/>
              <div className="layout-page">
              <Nave/>
             
              <div className="content-wrapper">
             <div className="container-xxl flex-grow-1 container-p-y">
              
            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light"></span> Plan Details</h4>
        <a href="/add_plan"><button className="btn btn-success">Add Plan</button></a>
        <br/><br/>
<div className="card">
  
  <div className="table-responsive text-nowrap">
    <table className="table">
      <thead>
        <tr>
          <th>Id</th>
          <th>Name</th>
          {/* <th>Status</th> */}
          <th>Actions</th>
        </tr>
      </thead>
      <tbody className="table-border-bottom-0">
        
        {
          plan.map((obj)=>{
            return(
              <tr>
              <td>{obj.id}</td>
              <td>{obj.name}</td>
             
              <td>
                <div className="dropdown">
                  <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                    <i className="bx bx-dots-vertical-rounded"></i>
                  </button>
                  <div className="dropdown-menu">
                    <a className="dropdown-item" onClick={()=>editOption(obj.id)}><i className="bx bx-edit-alt me-1"></i> Edit</a>
                    <a className="dropdown-item" onClick={()=>deleteOption(obj.id)}><i className="bx bx-trash me-1"></i> Delete</a>
                  </div>
                </div>
              </td>
              </tr>
            )
          })
        }
         
      
      </tbody>
    </table>
  </div>
</div>

                 </div>
                 </div>
                 <Footer/>
                 </div>   
                 </div>
                 </div>
                
               </React.Fragment>
    );
}

export default Plan;
