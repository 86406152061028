import React from 'react';

const Header = () => {
    return (
      <React.Fragment>
        <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
          <div className="app-brand demo">
            <a href="index.html" className="app-brand-link">
              <span className="app-brand-logo demo">
                <img src="/assets/img/favicon/icon.png" width={150}/>
              </span>
              <span className="app-brand-text demo menu-text fw-bolder ms-2"></span>
            </a>

            <a href="javascript:void(0);" className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
              <i className="bx bx-chevron-left bx-sm align-middle"></i>
            </a>
          </div>

          <div className="menu-inner-shadow"></div>

          <ul className="menu-inner py-1">
             <li className="menu-item">
              <a href="/" className="menu-link">
                <i className="menu-icon tf-icons bx bx-home-circle"></i>
                <div data-i18n="Analytics">Dashboard</div>
              </a>
            </li>

            
            <li className="menu-item">
              <a href="/category" className="menu-link">
                <i className="menu-icon tf-icons bx bx-layout"></i>
                <div data-i18n="Layouts">Category</div>
              </a>
            </li>
            <li className="menu-item">
              <a href="/meals" className="menu-link">
                <i className="menu-icon tf-icons bx bx-layout"></i>
                <div data-i18n="Layouts">Meal Type</div>
              </a>
            </li>
           
            {/* <li className="menu-item">
              <a href="/plans" className="menu-link">
                <i className="menu-icon tf-icons bx bx-layout"></i>
                <div data-i18n="Layouts">Plans</div>
              </a>
            </li>
            <li className="menu-item">
              <a href="/subscription" className="menu-link">
                <i className="menu-icon tf-icons bx bx-layout"></i>
                <div data-i18n="Layouts">Subscription</div>
              </a>
            </li> */}
           <li className="menu-item">
              <a href="/tiffintype" className="menu-link">
                <i className="menu-icon tf-icons bx bx-layout"></i>
                <div data-i18n="Layouts">Tiffin Type</div>
              </a>
            </li>
            <li className="menu-item">
              <a href="/view_menu_card" className="menu-link">
                <i className="menu-icon tf-icons bx bx-layout"></i>
                <div data-i18n="Layouts">Menu Card</div>
              </a>
            </li>
            <li className="menu-item">
              <a href="/sabji_menu" className="menu-link">
                <i className="menu-icon tf-icons bx bx-layout"></i>
                <div data-i18n="Layouts">Sabji Menu</div>
              </a>
            </li>
            <li className="menu-item">
              <a href="/sabji_calendar" className="menu-link">
                <i className="menu-icon tf-icons bx bx-layout"></i>
                <div data-i18n="Layouts">Sabji Calendar</div>
              </a>
            </li>
            <li className="menu-item">
              <a href="/menu_card" className="menu-link">
                <i className="menu-icon tf-icons bx bx-layout"></i>
                <div data-i18n="Layouts">Tiffin</div>
              </a>
            </li>
          
          </ul>
        </aside>
      </React.Fragment>
    );
}

export default Header;
