import React,{useState,useEffect} from 'react';
import Header from '../Header';
import Footer from '../Footer';
import Nave from '../Nave';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
function Editsubscription  () {
    const [type,setType]=useState('');
    const [price,setPrice]=useState('');
    const [planname,setPlanname]=useState('');
    const [content,setContent]=useState('');
    const [pid,setPid]=useState('');
    const [plan,setPlan]=useState([]);
    const {id}=useParams();
     const [isTypeError,setTypeError]=useState(false);
    const [isPriceError,setPriceError]=useState(false);
    const [isPlannameError,setPlannameError]=useState(false);
    const [isContentError,setContentError]=useState(false);
    const warn = { borderWidth: 1, borderColor: '#f44336' }
    const nowarn = { borderWidth: 1, borderColor: '#d9dee3' }
    function handleTitleChange(datacontent)
    {
      setContent(datacontent)
    }
    function fetchsub(id)
    {
        axios.get("http://localhost:8000/api/subscription/"+id)
        .then((res)=>{
            const data=res.data;
            setType(data[0].type);
            setPrice(data[0].price);
            setPlanname(data[0].name);
            setContent(data[0].content);
            setPid(data[0].pid);
        })
    }
    function handleSubmit()
    {
      if(planname=="")
      {
        toast.error("Select Name");
        setPlanname();
        setPlannameError(true);
      }else if(type=="")
      {
        toast.error("Select Type");
        setType();
        setTypeError(true);
      }else if(price=="")
      {
        toast.error("Enter Price");
        setPrice();
        setPriceError(true);
      }else if(content=="")
      {
        toast.error("Enter Content");
        setContent();
        setContentError(true);
      }
      else
      {
        const data={
            pid:pid,
            type:type,
            price:price,
            content:content
        };
        axios.patch("http://localhost:8000/api/subscription/"+id,data)
        .then((res)=>{
            const data=res.data;
            if(data[0].status=='success')
            {
                window.location.href='/subscription';
            }
            else
            {
                console.log(data);
            }
        })
      }
    }
    function fetplan()
    {
        axios.get("http://localhost:8000/api/plan")
        .then((res)=>{
            const data=res.data;
            setPlan(data);
        })
    }
    useEffect(()=>{
        fetchsub(id);
        fetplan();
    },[]);
    return (
        <React.Fragment>
        <div className="layout-wrapper layout-content-navbar">
              <div className="layout-container">
              <Header/>
              <div className="layout-page">
              <Nave/>
             
              <div className="content-wrapper">
             <div className="container-xxl flex-grow-1 container-p-y">
             <div className="row">
                <div className="col-xl">
                  <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                      <h5 className="mb-0">Subscription</h5>
                      {/* <small className="text-muted float-end">Default label</small> */}
                    </div>
                    <div className="card-body">
                        <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Plan Name</label>
                          <select className="form-control" id="basic-default-fullname" placeholder="Category Name" onChange={(planname) => {setPlanname(planname.target.value) 
                          setPlanname(false)
                          }}
                          style={isPlannameError ? warn : nowarn}>
                          <option value={pid}>{planname}</option>
                         {
                            plan.map((obj)=>{
                            return(
                                <option value={obj.id}>{obj.name}</option>
                                )
                            })
                            
                         }
                          </select>
                        </div>
                        <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Plan Type</label>
                          <select className="form-control" id="basic-default-fullname" placeholder="Category Name" 
                          onChange={(type) => {setType(type.target.value)
                          setType(false)
                          }}
                          style={isTypeError ? warn : nowarn}>
                          <option value={type}>{type}</option>
                        <option value="B2B">B2B</option>
                        <option value="B2C">B2C</option>
                          </select>
                        </div>
                        <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Price</label>
                          <input type="text" className="form-control" id="basic-default-fullname" placeholder="Price" value={price} 
                          onChange={(price) => {setPrice(price.target.value)
                            setPrice('')
                          }}
                          style={isPriceError ? warn : nowarn}/>
                        </div>
                        <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Content</label>
                          {/* <textarea type="text" className="form-control" id="basic-default-fullname" placeholder="Content" value={content} 
                          onChange={(content) => {setContent(content.target.value)
                            setContent('')
                          }}
                          style={isContentError ? warn : nowarn}>{content}</textarea> */}
                          <SunEditor setOptions={{height:700,}} setContents={content} style={{height:300,}} 
                          onChange={handleTitleChange} />
                        </div>
                        <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Send</button>
                      
                    </div>
                  </div>
                </div>
               
              </div>
            </div>
            

            <div className="content-backdrop fade"></div>
          </div>
             <Footer/>
             </div>
             </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}

export default Editsubscription;
