
import './App.css';
import Index from './Component/Index';
import Category from './Component/category/Category';
import AddCategory from './Component/category/Addcategory';
import EditCategory from './Component/category/Editcategory';
import Meals from './Component/meals/Meal';
import Addmeal from './Component/meals/Addmeal';
import Editmeal from './Component/meals/Editmeal';
import Plan from './Component/plan/Plan';
import Addplan from './Component/plan/Addplan';
import Editplan from './Component/plan/Editplan';
import Subscription from './Component/subscription/Subscription';
import Addsubscription from './Component/subscription/Addsubscription';
import Editsubscription from './Component/subscription/Editsubscription';
import Menucard from './Component/menucard/Menucard';
import Addmenucard from './Component/menucard/Addmenucard';
import Editmenucard from './Component/menucard/Editmenucard';
import Date from './Component/menucard/Date';
import Dashboard from './Component/Dashboard';
import TiffinType from './Component/tiffintype/TiffinDisplay';
import AddType from './Component/tiffintype/TiffinType';
import EditType from './Component/tiffintype/Edittype';
import Addmenu from './Component/tiffinmenu/Addtiffinmenu';
import TiffinMenucard from './Component/tiffinmenu/Tiffinmenucard';
import Edittiffn from './Component/tiffinmenu/Edittiffin';
import SabjiMenuCalendar from './Component/SabjiCalendar/Menucard';
import AddSabjiMenuCalendar from './Component/SabjiCalendar/Addmenucard';
import EditSabjiMenuCalendar from './Component/SabjiCalendar/Editmenucard';
import SabjiMenu from './Component/Sabjimenu/Menucard';
import AddSabjiMenu from './Component/Sabjimenu/Addmenucard';
import EditSabjiMenu from './Component/Sabjimenu/Editmenucard';
import {BrowserRouter,Routes,Route} from 'react-router-dom';
function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<Dashboard/>}/>
      <Route path='/category' element={<Category/>}/>
      <Route path='/add_category' element={<AddCategory/>}/>
      <Route path='/editcategory/:id' element={<EditCategory/>}/>
      <Route path='/meals' element={<Meals/>}/>
      <Route path='/add_meal' element={<Addmeal/>}/>
      <Route path='/editmeal/:id' element={<Editmeal/>}/>
      <Route path='/plans' element={<Plan/>}/>
      <Route path='/add_plan' element={<Addplan/>}/>
      <Route path='/editplan/:id' element={<Editplan/>}/>
      <Route path='/subscription' element={<Subscription/>}/>
      <Route path='/add_subscription' element={<Addsubscription/>}/>
      <Route path='/editsubscription/:id' element={<Editsubscription/>}/>
      <Route path='/menu_card' element={<Menucard/>}/>
      <Route path='/add_menu' element={<Addmenucard/>}/>
      <Route path='/editmenu/:id' element={<Editmenucard/>}/>
      <Route path='/Date' element={<Date/>}/>
      <Route path='/tiffintype' element={<TiffinType/>}/>
      <Route path='/add_type' element={<AddType/>}/>
      <Route path='/edittype/:id' element={<EditType/>}/>
      <Route path='/view_menu_card' element={<TiffinMenucard/>}/>
      <Route path='/tiffin_menu_card' element={<Addmenu/>}/>
      <Route path='/edittiffin/:id' element={<Edittiffn/>}/>
      <Route path='/sabji_calendar' element={<SabjiMenuCalendar/>}/>
      <Route path='/add_sabjicalendar' element={<AddSabjiMenuCalendar/>}/>
      <Route path='/editsabjicalendar/:id' element={<EditSabjiMenuCalendar/>}/>
      <Route path='/sabji_menu' element={<SabjiMenu/>}/>
      <Route path='/add_sabji' element={<AddSabjiMenu/>}/>
      <Route path='/editsabji/:id' element={<EditSabjiMenu/>}/>
      {/* <Route path='/Dashboard' element={<Dashboard/>}/> */}
     </Routes>
    </BrowserRouter>
  );
}

export default App;
