import React,{useState} from 'react';
import Header from '../Header';
import Footer from '../Footer';
import Nave from '../Nave';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function TiffinType () {
    const [name,setName]=useState('');
    const [isNameError,setNameError]=useState(false);
    const warn = { borderWidth: 1, borderColor: '#f44336' }
    const nowarn = { borderWidth: 1, borderColor: '#d9dee3' }
    function handleSubmit(){
      if(name=="")
      {
        toast.error('Enter Type ');
        setName();
        setNameError(true)
      }
      else
      {
        const data={
            type:name
        };
        // axios.post("https:s://api.mytiffi.voidspy.com/api/category",data)
        axios.post("https://api.mytiffi.voidspy.com/api/tiffin_type",data)
            .then((res)=>{
                const data=res.data;
                if(data[0].status=='success')
                {
                    window.location.href='/tiffintype';
                }
                else
                {
                    console.log(data);
                }
            })
      }
        
    }
    return (

        <React.Fragment>
        <div className="layout-wrapper layout-content-navbar">
              <div className="layout-container">
              <Header/>
              <div className="layout-page">
              <Nave/>
             
              <div className="content-wrapper">
             <div className="container-xxl flex-grow-1 container-p-y">
             <div className="row">
                <div className="col-xl">
                  <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                      <h5 className="mb-0">Tiffin Type</h5>
                      {/* <small className="text-muted float-end">Default label</small> */}
                    </div>
                    <div className="card-body">
                     
                        <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Type</label>
                          <input type="text" className="form-control" id="basic-default-fullname" placeholder="Tiffin Type" value={name} 
                          onChange={(name) => {setName(name.target.value)
                          setNameError(false)
                          }} 
                          style={isNameError ? warn : nowarn}/>
                        </div>
                        
                        <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Send</button>
                      
                    </div>
                  </div>
                </div>
               
              </div>
            </div>
            

            <div className="content-backdrop fade"></div>
          </div>
             <Footer/>
             </div>
             </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}

export default TiffinType;
