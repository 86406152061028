import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import Nave from '../Nave';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
function Addmenucard() {

  const [menuname, setMenuname] = useState('');
  const [catename, setCatename] = useState('');
  const [typename, setType] = useState('');
  const [price, setPrice] = useState('');
  const [category, setCategory] = useState([]);
  const [drysabji,setDrysabji]=useState([]);
  const [currysabji,setCurrysabji]=useState([]);
  // const [meals, setMeals] = useState([]);
  const [tiffintype, setTiffintype] = useState([]);
  const [selectedMeals, setSelectedMeals] = useState([]);
  const [content, setContent] = useState('');
  const [mtype, setMType] = useState('');
  const warn = { borderWidth: 1, borderColor: '#f44336' }
  const nowarn = { borderWidth: 1, borderColor: '#d9dee3' }
  const [isDateError, setDateError] = useState(false);
  const [isNameError, setNameError] = useState(false);
  const [isCatenameError, setCatenameError] = useState(false);
  const [isTypenameError, setTypenameError] = useState(false);
  const [isMTypeError, setMTypeError] = useState(false);
  const [isPriceError, setPriceError] = useState(false);
  const [isSelecteMealError, setSelectMealError] = useState(false);
  const [userinfo, setUserInfo] = useState({
    languages: [],
    response: [],
  });
  const [inputFields, setInputFields] = useState([
    { name: '' }
  ])
  const [inputFieldsTuesday, setInputFieldsTuesday] = useState([
    { name: '' }
  ])

  const handleChangeSabji = (e) => {
    // Destructuring
    // let checkArray = [];
    // checkArray.push(e.target.value);
    // console.log(checkArray);
    setMenuname(e.target.value);
  };

  const handleChangeSabjiCurry = (e) => {
    // Destructuring
    // let checkArray = [];
    // checkArray.push(e.target.value);
    // console.log(checkArray);
    setMType(e.target.value);
  };

  function handleSubmit() {
    // if (!catename) {
    //   setCatenameError(true);
    // } else if (!typename) {
    //   setTypenameError(true);
    // }
    // else if (!mtype) {
    //   setMTypeError(true)
    // }
    // else if (!price) {
    //   setPriceError(true)
    // }
   
    // else {
      const menudata = {
        name: menuname,
        cate: catename,
        date: price,
        meal:typename ,
        type: mtype,

      }
      console.log(menudata);
      axios.post("https://api.mytiffi.voidspy.com/api/sabji_menu", menudata)
        .then((res) => {
          const data = res.data;
          //console.log(data);
          if (data[0].status == 'success') {
            window.location.href = '/sabji_calendar';
          }
          else {
            console.log(data);
          }
        })
   

  }
  function handleGetData(e)
  {
      const cate={
        catename:e,
      }
      console.log(cate);
      axios.post("https://api.mytiffi.voidspy.com/api/getdrysabji", cate)
        .then((res) => {
          const data = res.data;
          setDrysabji(data);
         // console.log(data[0].name);
          
        })
        axios.post("https://api.mytiffi.voidspy.com/api/getcurrysabji", cate)
        .then((res) => {
          const data = res.data;
          setCurrysabji(data);
         // console.log(data[0].name);
          
        })
  }
  // function fetchMeal() {
  //   // axios.get('https://api.mytiffi.voidspy.com/api/mealget')
  //   axios.get('https://api.mytiffi.voidspy.com/api/mealget')
  //     .then((resp) => {
  //       const data = resp.data;
  //       setMeals(data);

  //     });

  // }
  function handlePlus(meal) {
    var t = []
    selectedMeals.map((m) => {
      if (meal.id == m.meal.id) {
        m.qty++

      }
      t.push(m);
    })
    setSelectedMeals(t);
    console.log(selectedMeals);
  }
  function handleMinus(meal) {
    var t = []
    selectedMeals.map((m) => {
      if (meal.id == m.meal.id) {
        m.qty--

      }
      t.push(m);
    })
    setSelectedMeals(t);
    console.log(selectedMeals);
  }
  function handleChange(e, meal) {
    setSelectMealError(false)
    if (e.target.checked) {
      var tm = selectedMeals
      tm.push({ "meal": meal, "qty": 1, })
      setSelectedMeals(tm);
    }
    else {
      var t = []
      selectedMeals.map((m) => {
        if (meal.name != m.meal.name) {
          t.push(m);
        }
      })
      setSelectedMeals(t);
      console.log(t);
    }
    // console.log(selectedMeals);
  }
  function fetchCategory() {
    // axios.get("https://api.mytiffi.voidspy.com/api/category")
    axios.get("https://api.mytiffi.voidspy.com/api/category")
      .then((res) => {
        const data = res.data;
        setCategory(data);
      })
  }
  function fetchTiffin() {
    axios.get('https://api.mytiffi.voidspy.com/api/meal')
      .then((resp) => {
        const data = resp.data;
        setTiffintype(data);

      });

  }
 

  useEffect(() => {
    fetchCategory();
    //fetchMeal();
    fetchTiffin();
  }, []);
  return (
    <React.Fragment>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header />
          <div className="layout-page">
            <Nave />

            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row">
                  <div className="col-xl">
                    <div className="card mb-4">
                      <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Sabji Menu</h5>
                        {/* <small className="text-muted float-end">Default label</small> */}
                      </div>
                      <div className="card-body">

                        {/* <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Menu Name {isNameError && <span style={{ color: 'red' }}>Please Enter name</span>}</label>
                          <input type="text" className="form-control" id="basic-default-fullname" name='name' value={menuname}
                            onChange={(menuname) => {
                              setMenuname(menuname.target.value)
                              setNameError(false);
                            }} style={isNameError ? warn : nowarn} />
                        </div> */}

                        <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Date {isPriceError && <span style={{ color: 'red' }}>Please Select Date</span>}</label>
                          <input type="date" className="form-control" id="basic-default-fullname" name='name' value={price}
                            onChange={(price) => {
                              setPrice(price.target.value)
                              setPriceError(false);
                            }} style={isPriceError ? warn : nowarn} />
                        </div>
                        <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Meal Type {isTypenameError && <span style={{ color: 'red' }}>Please select Meal Type</span>}</label>
                          <select className="form-control" id="basic-default-fullname" placeholder="Category Name" onChange={(typename) => {
                            setType(typename.target.value)
                            setTypenameError(false);
                          }} style={isTypenameError ? warn : nowarn}>
                            <option value="">Select Type</option>
                            {
                              tiffintype.map((obj) => {
                                return (
                                  <option value={obj.id}>{obj.name}</option>
                                )
                              })

                            }
                          </select>
                        </div>
                        <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Category Name {isCatenameError && <span style={{ color: 'red' }}>Please select Category</span>}</label>
                          <select className="form-control" id="basic-default-fullname" placeholder="Category Name" onChange={(catename) => {
                            setCatename(catename.target.value)
                            setCatenameError(false);
                            handleGetData(catename.target.value)
                          }} style={isCatenameError ? warn : nowarn}>
                            <option value="">Select Category</option>
                            {
                              category.map((obj) => {
                                return (
                                  <option value={obj.id}>{obj.name}</option>
                                )
                              })

                            }
                          </select>
                        </div>
                        
                        <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Dry</label><br/>
                          {/* <select className="form-control" id="basic-default-fullname" placeholder="Category Name" onChange={(mtype) => {
                            setMType(mtype.target.value)
                            setMTypeError(false);
                          }} style={isMTypeError ? warn : nowarn}>
                            <option value="">Select   </option> */}
                            {
                              drysabji.map((obj) => {
                                return (
                                 
                                  <span><input type="radio" value={obj.name} name='drysubji' style={{marginRight:"10px",marginLeft:"10px"}} onChange={handleChangeSabji}/>{obj.name} </span> 
                                )
                              })

                            }
                          {/* </select> */}
                        </div>
                        <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Curry</label><br/>
                          {/* <select className="form-control" id="basic-default-fullname" placeholder="Category Name" onChange={(mtype) => {
                            setMType(mtype.target.value)
                            setMTypeError(false);
                          }} style={isMTypeError ? warn : nowarn}>
                            <option value="">Select   </option> */}
                            {
                              currysabji.map((obj) => {
                                return (
                                 
                                  <span><input type="radio" value={obj.name} name='currysubji' style={{marginRight:"10px",marginLeft:"10px"}} onChange={handleChangeSabjiCurry}/>{obj.name} </span> 
                                )
                              })

                            }
                          {/* </select> */}
                        </div>
                        
                       
                        {/* <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Content</label>
                          
                          <SunEditor setOptions={{ height: 700, }} style={{ height: 300, }}
                            onChange={handleTitleChange} />
                        </div> */}
                        <p id="GFG_DOWN"></p>
                        <button type="button" className="btn btn-primary" onClick={() => handleSubmit()}>Store</button>

                      </div>
                    </div>
                  </div>

                </div>
              </div>


              <div className="content-backdrop fade"></div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

export default Addmenucard;
