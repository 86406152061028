import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Nave from '../Nave';
import Footer from '../Footer';
import axios from 'axios';

function Subscription  () {
    const [subscription,setSubscription]=useState([]);
    function fetchsub(){
        axios.get("http://localhost:8000/api/subscription")
        .then((res)=>{
            const data=res.data;
            setSubscription(data);
        })
    }
    function editOption(id)
    {
        window.location.href='editsubscription/'+id;
    }
    function deleteOption(id)
    {
        axios.delete("http://localhost:8000/api/subscription/"+id)
        .then((res)=>{
            const data=res.data;
            if(data[0].status=='success')
            {
                window.location.href='/subscription';
            }
            else
            {
                console.log(data);
            }
        })

    }
    useEffect(()=>{
        fetchsub();
    },[]);
    return (
        <React.Fragment>
        <div className="layout-wrapper layout-content-navbar">
              <div className="layout-container">
              <Header/>
              <div className="layout-page">
              <Nave/>
             
              <div className="content-wrapper">
             <div className="container-xxl flex-grow-1 container-p-y">
              
            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light"></span> Subscription Details</h4>
        <a href="/add_subscription"><button className="btn btn-success">Add Subscription</button></a>
        <br/><br/>
<div className="card">
  
  <div className="table-responsive text-nowrap">
    <table className="table">
      <thead>
        <tr>
          <th>Id</th>
          <th>Product Name</th>
          <th>Content</th>
          <th>Type</th>
          <th>Price</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody className="table-border-bottom-0">
        
        {
            subscription.map((obj)=>{
            return(
              <tr>
              <td>{obj.sid}</td>
              <td>{obj.name}</td>
              <td dangerouslySetInnerHTML={{__html: obj.content}}></td>
              <td>{obj.type}</td>
              <td>{obj.price}</td>
              <td>
                <div className="dropdown">
                  <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                    <i className="bx bx-dots-vertical-rounded"></i>
                  </button>
                  <div className="dropdown-menu">
                    <a className="dropdown-item" onClick={()=>editOption(obj.sid)}><i className="bx bx-edit-alt me-1"></i> Edit</a>
                    <a className="dropdown-item" onClick={()=>deleteOption(obj.sid)}><i className="bx bx-trash me-1"></i> Delete</a>
                  </div>
                </div>
              </td>
              </tr>
            )
          })
        }
         
      
      </tbody>
    </table>
  </div>
</div>

                 </div>
                 </div>
                 <Footer/>
                 </div>   
                 </div>
                 </div>
                
               </React.Fragment>
    );
}

export default Subscription;
