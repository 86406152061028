import React,{useState,useEffect} from 'react';
import Header from '../Header';
import Footer from '../Footer';
import Nave from '../Nave';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Addtiffinmenu () {
    const [name,setName]=useState('');
    const [catename, setCatename] = useState('');
   
    const [category, setCategory] = useState([]);

    const [flag,setFlag]=useState('');
    const [tiffin,setTiffin]=useState([]);
    
    const [isNameError,setNameError]=useState(false);
    const [isCatenameError, setCatenameError] = useState(false);
    const [price, setPrice] = useState('');
    const [isPriceErrortype, setPriceError] = useState(false);
    const warn = { borderWidth: 1, borderColor: '#f44336' }
    const nowarn = { borderWidth: 1, borderColor: '#d9dee3' }
    var caten=catename;
    console.log(caten);
    
      function fetchtType(){
      if(caten!="")
      {
      axios.get("https://api.mytiffi.voidspy.com/api/gettype/"+caten)
      .then((res)=>{
        const data=res.data;
        console.log(data);
      });
      }
    }
    function fetchTiffin(){
      axios.get("https://api.mytiffi.voidspy.com/api/menu_card")
      .then((res)=>{
        const data=res.data;
        setTiffin(data);
      })
    }
    function fetchCategory() {
      // axios.get("https://api.mytiffi.voidspy.com/api/category")
      axios.get("https://api.mytiffi.voidspy.com/api/category")
        .then((res) => {
          const data = res.data;
          setCategory(data);
        })
    }
    function handleSubmit(){
      if(catename=="")
      {
        toast.error('Enter Name');
        setCatename();
        setCatenameError(true)
      }else if(price=="")
      {
        toast.error('Enter Price');
        setPrice();
        setPriceError(true)
      }
      
      else
      {
        const data={
            
            name:catename,
            
            price:price,
        };
        console.log(data);

        // axios.post("https://api.mytiffi.voidspy.com/api/category",data)
        axios.post("https://api.mytiffi.voidspy.com/api/menu_card",data)
            .then((res)=>{
                const data=res.data;
                if(data[0].status=='success')
                {
                    window.location.href='/view_menu_card';
                }
                else
                {
                    console.log(data);
                }
            })
      }
        
    }
    useEffect(()=>{
      fetchTiffin();
      fetchtType();
      fetchCategory()
    },[])
    return (

        <React.Fragment>
        <div className="layout-wrapper layout-content-navbar">
              <div className="layout-container">
              <Header/>
              <div className="layout-page">
              <Nave/>
             
              <div className="content-wrapper">
             <div className="container-xxl flex-grow-1 container-p-y">
             <div className="row">
                <div className="col-xl">
                  <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                      <h5 className="mb-0">Menu Card</h5>
                      {/* <small className="text-muted float-end">Default label</small> */}
                    </div>
                    <div className="card-body">
                    <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Name {isCatenameError && <span style={{ color: 'red' }}>Please Enter Name</span>}</label>
                          <input type="text" className="form-control" id="basic-default-fullname" placeholder="Menu Name" onChange={(catename) => {
                            setCatename(catename.target.value)
                            setCatenameError(false);
                          }} style={isCatenameError ? warn : nowarn} />
                            
                        </div>
                      
                        <div className="mb-3">
                          <label className="form-label" for="basic-default-fullname">Price {isCatenameError && <span style={{ color: 'red' }}>Please Enter Price</span>}</label>
                          <input type="text" className="form-control" id="basic-default-fullname" placeholder="Price" onChange={(price) => {
                            setPrice(price.target.value)
                            setPriceError(false);
                          }} style={isPriceErrortype ? warn : nowarn}/>
                            
                        </div>
                        </div>
                        <div className="mb-3">
                        
                        <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Send</button>
                      
                    </div>
                  </div>
                </div>
               
              </div>
            </div>
            

            <div className="content-backdrop fade"></div>
          </div>
             <Footer/>
             </div>
             </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}

export default Addtiffinmenu;
